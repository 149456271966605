import { isUserEngagementInteractivitySelector } from '@redux/experiments/selectors/user-engagement-interactivity';
import { useAppSelector } from '@redux/hooks';
import { useStorefrontBookNow } from './useStorefrontBookNow';

export const useUserEngagementInteractivity = (): boolean => {
	const vendor = useAppSelector((state) => state.vendor.vendor);
	const isBookNow = useStorefrontBookNow();

	const isUserEngagementInteractivity = useAppSelector(
		isUserEngagementInteractivitySelector,
	);

	const isPaid = vendor?.isPaid ?? false;
	return isPaid && isUserEngagementInteractivity && !isBookNow;
};
